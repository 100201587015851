import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ChatService from 'myt-chat/src/external/ChatService';
import ChannelUtils from 'myt-chat/src/chat/utils/ChannelUtils';
import UserRole from 'myt-chat/src/chat/enums/UserRole';
import Spinner from '../../util_components/spinner';
import * as actions from '../../../redux/action_creators';
import * as selectors from '../../../redux/selectors';
import SelDateTime from '../user_sch_session/SelDateTime';
import Confirm from '../user_sch_session/Confirm';
import './profile.css';
import {
  setCookie,
  removeCookie,
  getCookie,
  capitalizeFirstLetter,
  sendEventToClarity
} from '../../../util_functions';
import urls from '../../../urls';
import DashboardScheduleClassPopup from '../../../new_teacher_design/components/popups/DashboardScheduleClassPopup';
import ScheduleConfirmation from '../../../new_teacher_design/components/popups/ScheduleConfirmation';
import SubscritionPopup from '../../../new_teacher_design/components/popups/CreditPopup';

import { withRouter } from '../../../utils/router';
import { StudentProfileIcon } from '../../../../svg';
import TextWithLinks from '../../util_components/CustomTextWithLink';

class StudentProfile extends Component {
  state = {
    loading: true,
    open_sch_sess_with_student: false,
    confirm_sch_session: false,
    open_student_yoga_journey: false,
    channelItem: undefined,
    isScheduleClass: false,
    sessionScheduledSuccess: false,
    subscritiopnPopup: false,
    subscritiopnPopupDataDT: {},
    channelUnread: 0
  };

  findChannel = () => ChannelUtils.makeChannel(this.props.student_details.uuid, UserRole.STUDENT);

  componentDidMount() {
    if (
      this.props.student_details !== '' &&
      this.props.student_details.uuid === this.props.student_uuid
    ) {
      this.setState({ loading: false });
    } else {
      const payload = {
        student_slug: this.props.router.params.studentSlug
      };
      this.props.load_student_details_slug(payload);

      const channelID = this.findChannel();
      fetch(urls.chat_unread.replace(':CHANNEL_ID', channelID), {
        headers: {
          Authorization: `Bearer ${getCookie('teacher_token_1')}`
        }
      })
        .then((x) => x.json())
        .then((response) => {
          if (response.unread) {
            this.setState({
              channelUnread: response.unread
            });
          }
        })
        .catch((e) => console.log(e));
    }

    sendEventToClarity();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.student_loading_status === 'loading' &&
      this.props.student_loading_status === 'success'
    ) {
      this.setState({ loading: false });
    }

    if (
      prevProps.impersonate_myt_user_teacher_consultancy_response_status === 'loading' &&
      this.props.impersonate_myt_user_teacher_consultancy_response_status === 'success'
    ) {
      setCookie(
        'impersonationFromTeacherToken',
        this.props.impersonate_myt_user_teacher_consultancy_response.token
      );
      removeCookie('student_token');
      const url = urls.student_impersonation_url;
      window.open(
        `${url}&consult_id=`,
        '',
        'directories=no,toolbar=no,location=0,status=no,menubar=no, statusbar=0, scrollbars=no,resizable=no'
      );
    }
    if (
      prevProps.impersonate_myt_user_teacher_consultancy_response_status === 'loading' &&
      this.props.impersonate_myt_user_teacher_consultancy_response_status === 'fail'
    ) {
      alert('Something went wrong');
    }
    if (
      prevProps.schedule_class_status === 'loading' &&
      this.props.schedule_class_status === 'success'
    ) {
      this.setState({ isScheduleClass: false });
    }
    if (
      prevProps.schedule_class_status === 'loading' &&
      this.props.schedule_class_status === 'fail'
    ) {
      this.setState({ reason: this.props.schedule_class_response.reason });
    }
  }

  open_chat_screen = () => {
    ChatService.openP2PChat(this.props.student_details.uuid);
  };

  open_sch_sess_w_stu = () => this.setState({ open_sch_sess_with_student: true });

  close_sch_sess_w_stu = () => this.setState({ open_sch_sess_with_student: false });

  sch_confirm = () =>
    this.setState({
      open_sch_sess_with_student: false,
      confirm_sch_session: true
    });

  goToHome = () => this.props.router.navigate('/home');

  open_student_yoga_journey = () => {
    const is_present = this.props.student_details.is_roadmap_present === 1;
    this.props.set_home_variable('is_roadmap_present', is_present);
    this.props.router.navigate(`/yoga-journey/${this.props.router.params.studentSlug}`, {
      state: {
        is_roadmap_present: this.props.student_details.is_roadmap_present,
        sessions_scheduled: this.props.student_details.number_of_sessions_scheduled,
        sessions_finished: this.props.student_details.number_of_sessions_finished,
        student_uuid: this.props.student_details.uuid,
        profile_photo: this.props.student_details.profile_photo_thumbnail,
        student_name: this.props.student_details.full_name
      }
    });
  };

  go_to_roadmap = () => {
    this.props.router.navigate(`/road-map/${this.props.router.params.studentSlug}`);
  };

  openScheduleClassPopup = () => {
    this.setState({ isScheduleClass: true });
  };

  openBookCoaching = () =>
    this.props.router.navigate(`/book-coaching?id=${this.props.student_details.uuid}`);

  closeScheduleClassPopup = () => {
    this.setState({ isScheduleClass: false });
  };

  closeSchedulePopup = () => {
    this.setState({ isScheduleClass: false });
  };

  openCreditPopup = (dt) => {
    this.setState({ subscritiopnPopupDataDT: dt, isScheduleClass: false, subscritiopnPopup: true });
  };

  closesubscriptionPopupFun = () => {
    this.setState({ isScheduleClass: false, sessionScheduledSuccess: true });
  };

  closeSessionSuccess = () => {
    this.setState({ sessionScheduledSuccess: false });
  };

  closesubscriptionPopupFail = () => {
    this.setState({
      subscritiopnPopup: false,
      isScheduleClass: true
    });
  };

  closesubscriptionPopup = () => {
    this.setState({
      subscritiopnPopup: false,
      sessionScheduledSuccess: true
    });
  };

  closeSubscriptionPopupdt = () => {
    this.setState({ subscritiopnPopup: false });
    this.props.set_home_variable('scheduleSessionDataInfoErrMsg', '');
    this.props.set_home_variable('scheduleSessionDataInfo', {});
  };

  renderBlock = (text, index) => {
    if (text) {
      return (
        <div className="vs-text-block" key={index + text}>
          {text.toUpperCase()}
        </div>
      );
    }
    return null;
  };

  renderCorporateUserBadge() {
    const {
      is_glean_customer = 0,
      is_reflexion_customer = 0,
      age,
      gender
    } = this.props?.student_details || {};
    if (is_glean_customer === 1 || is_reflexion_customer === 1) {
      return (
        <div className="bg-[#FFF387] text-[#685F10] font-inter text-10px font-medium px-4px py-3px w-auto leading-10px ml-3 rounded-sm">
          {(age > 0 || gender) && ' '}
          Corporate User - {is_glean_customer === 1 ? 'Glean' : 'Reflexion'}
        </div>
      );
    }
    return null;
  }

  render_student_details = () => {
    const stu_dp = {
      backgroundImage: `url(${
        this.props.student_details.profile_photo === ''
          ? 'https://images.myyogateacher.com/profile-pic.png'
          : this.props.student_details.profile_photo
      })`
    };
    const { slug } = this.props.student_details;
    const is_concierge = this.props.teacher_details.opt_in_concierge;
    const { is_admin_imp } = this.props;

    const scheduleData = {
      is_recurring: false,
      student_uuid: this.props.student_details.uuid,
      student_name: this.props.student_details.full_name,
      epoch_start_time:
        moment().minutes() > 30
          ? moment()
              .add(60 - moment().minutes(), 'minutes')
              .valueOf()
          : moment()
              .add(30 - moment().minutes(), 'minutes')
              .valueOf(),
      duration: 60,
      ...this.props.student_details
    };

    const getScreenSize = window?.innerWidth > 768;

    return (
      <>
        <div className={`${getScreenSize ? 'vs-container' : 'pb-[60px]'} `}>
          <div className="vs-header">
            <div className="vs-bg" />
            <div className="vs-name-details">
              <h4 className="vs-name">
                {this.props.student_details.first_name}
                <span
                  style={{
                    fontWeight: 'normal',
                    color: '#afafaf',
                    fontSize: '14px',
                    marginLeft: '5px'
                  }}
                >
                  (Unique ID:{' '}
                  {is_concierge === 1 || is_admin_imp ? (
                    <a
                      href={`https://hridaya.myyogateacher.com/backpain-client-profile?id=${this.props.student_details.uuid}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      {slug}
                    </a>
                  ) : (
                    slug
                  )}
                  )
                </span>
                {this.props.student_details?.trial_end_date !== '' ? (
                  <span
                    className="font-inter text-10px font-bold rounded-4px px-4px py-3px w-auto leading-10px"
                    style={{
                      backgroundColor:
                        this.props.student_details?.offer_type === '2_1on1_fitness_1wk' ||
                        this.props.student_details?.offer_type === '3-free-1on1--30-day'
                          ? ''
                          : '#FFF387',
                      color:
                        this.props.student_details?.offer_type === '2_1on1_fitness_1wk' ||
                        this.props.student_details?.offer_type === '3-free-1on1--30-day'
                          ? ''
                          : '#685F10'
                    }}
                  >
                    Trial ends {this.props.student_details?.trial_end_date}
                  </span>
                ) : null}
              </h4>
              <div className="vs-details">
                <div className="opacity-50">
                  {this.props.student_details.age > 0 ? (
                    <>Age: {this.props.student_details.age} years old</>
                  ) : null}
                </div>
                <div className="opacity-50 uppercase">
                  {this.props.student_details.gender ? (
                    <>, &nbsp;{capitalizeFirstLetter(this.props?.student_details?.gender || '')}</>
                  ) : null}
                </div>
                {this.props.student_details?.offer_type === '2_1on1_fitness_1wk' && (
                  <div
                    className=" font-inter text-10px font-bold rounded-4px px-4px py-3px w-auto ml-2"
                    style={{
                      backgroundColor: '#FFF387',
                      color: '#685F10',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    1on1_fitness_7days
                  </div>
                )}
                <div>{this.renderCorporateUserBadge()}</div>
                {this.props?.student_details?.is_glean_customer === 1 ? (
                  <div className="ml-3 font-inter font-medium text-white">
                    {this.props.student_details.free_1_on_1_session} Free 1-on-1's and{' '}
                    {this.props.student_details.total_trial_days} day Trial
                  </div>
                ) : null}
              </div>
              <div
                className={`vs-details ${this.props?.student_details?.is_glean_customer === 1 ? '' : 'opacity-50'}`}
              >
                {this.props?.student_details?.is_glean_customer === 1 ? (
                  <div>
                    <span className="font-inter font-medium text-white">
                      {this.props.student_details.one_on_one_session_completed}
                      &#160; 1-on-1{' '}
                      {this.props.student_details.one_on_one_session_completed <= 1
                        ? 'Session'
                        : 'Sessions'}{' '}
                      Done
                    </span>
                    <span className="font-inter font-medium text-white">
                      {this.props.student_details.group_classes_completed}
                      &#160; Group{' '}
                      {this.props.student_details.group_classes_completed <= 1
                        ? 'Class'
                        : 'Classes'}{' '}
                      Done{' '}
                    </span>
                  </div>
                ) : (
                  <div>
                    {this.props.student_details.yoga_level && (
                      <span>{this.props.student_details.yoga_level.toUpperCase()}</span>
                    )}
                    {this.props.student_details.number_of_sessions_finished !== null &&
                      this.props.student_details.number_of_sessions_finished !== undefined && (
                        <span>
                          {this.props.student_details.number_of_sessions_finished} SESSIONS DONE
                        </span>
                      )}
                    {this.props.student_details.membership_plan && (
                      <span>{this.props.student_details.membership_plan.toUpperCase()}</span>
                    )}
                    {this.props.student_details?.student_package_plan && (
                      <span>
                        {' '}
                        {this.props.student_details?.student_package_plan?.toUpperCase()}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="vs-profile-pic" style={stu_dp} />
          </div>
          <div className="vs-basic-details">
            {!!this.props?.student_details?.concierge_name && (
              <div className="vs-basic-container">
                <div className="vs-basic-icon">
                  <StudentProfileIcon />
                </div>
                <div className="vs-basic-desc">
                  <h4 className="vs-basic-desc-title">Concierge</h4>
                  <p className="vs-basic-desc-text">
                    {this.props?.student_details?.concierge_name}
                  </p>
                </div>
              </div>
            )}
            <div className="vs-basic-container">
              <div className="vs-basic-icon">
                <img
                  src="https://images.myyogateacher.com/teacher_web_app/ic_yoga@2x.png"
                  alt="yoga"
                />
              </div>
              <div className="vs-basic-desc">
                <h4 className="vs-basic-desc-title">Experience with Yoga</h4>
                <p className="vs-basic-desc-text">
                  <span className="exp-p">{this.props.student_details.yoga_level}</span>,{' '}
                  {this.props.student_details.years_of_experience}
                </p>
              </div>
            </div>

            {this.props.student_details.yoga_types_practiced.trim() !== '' ? (
              <div className="vs-basic-container">
                <div className="vs-basic-icon">
                  <img
                    className="mat"
                    src="https://images.myyogateacher.com/teacher_web_app/ic_yoga_practiced@2x.png"
                    alt="yoga"
                  />
                </div>
                <div className="vs-basic-desc">
                  <h4 className="vs-basic-desc-title">Types of Yoga practiced</h4>
                  <div className="vs-basic-desc-text">
                    {this.props.student_details.yoga_types_practiced
                      .split(',')
                      .map((item, index) => this.renderBlock(item, index))}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="vs-basic-container">
              <div className="vs-basic-icon">
                <img
                  src="https://images.myyogateacher.com/teacher_web_app/ic_goals@2x.png"
                  alt="yoga"
                />
              </div>
              <div className="vs-basic-desc">
                <h4 className="vs-basic-desc-title">Goals</h4>
                <div className="vs-basic-desc-text">
                  {this.props.student_details.goals
                    .split(',')
                    .map((item, index) => this.renderBlock(item, index))}
                </div>
              </div>
            </div>
            {this.props.student_details.medical_history_details.length !== 0 ||
            this.props.student_details.health_history_list.length !== 0 ? (
              <div className="vs-basic-container">
                <div className="vs-basic-icon">
                  <img
                    src="https://images.myyogateacher.com/teacher_web_app/ic_medical_history@2x.png"
                    alt="yoga"
                  />
                </div>
                <div className="vs-basic-desc">
                  <h4 className="vs-basic-desc-title">Medical history</h4>
                  <p className="vs-basic-desc-text">
                    {this.props.student_details.medical_history_details}
                  </p>
                  <div className="vs-basic-desc-text">
                    {this.props.student_details.health_history_list.length !== 0 &&
                      this.props.student_details.health_history_list.map((item) => (
                        <div className="vs-files-block">
                          <img
                            className="vs-file-icon"
                            src="https://images.myyogateacher.com/file.png"
                            alt="file-icon"
                          />
                          <a href={item.file_path} rel="noreferrer noopener" target="_blank">
                            {item?.file_name}
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="vs-basic-container">
                <div className="vs-basic-icon">
                  <img
                    src="https://images.myyogateacher.com/teacher_web_app/ic_medical_history@2x.png"
                    alt="yoga"
                  />
                </div>
                <div className="vs-basic-desc">
                  <h4 className="vs-basic-desc-title">Medical history</h4>
                  <p className="vs-basic-desc-text">No medical history details</p>
                </div>
              </div>
            )}

            {this.props.student_details &&
            this.props.student_details?.student_additional_info !== '' ? (
              <div className="vs-basic-container">
                <div className="vs-basic-icon">
                  <img
                    src="https://images.myyogateacher.com/teacher_web_app/ic_medical_history@2x.png"
                    alt="yoga"
                  />
                </div>
                <div className="vs-basic-desc">
                  <h4 className="vs-basic-desc-title">Getting to know Student better</h4>
                  <div
                    className="gettingToKnowStyle break-words"
                    dangerouslySetInnerHTML={{
                      __html: this.props.student_details.student_additional_info
                    }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="vs-basic-container">
              <div className="vs-basic-icon">
                <img
                  src="https://images.myyogateacher.com/teacher_web_app/ic_medical_history@2x.png"
                  alt="medical-history-icon"
                />
              </div>
              <div className="vs-basic-desc">
                <h4 className="vs-basic-desc-title">First 1-on-1 Trial Session Notes</h4>
                <p className="vs-basic-desc-text">
                  <div className="w-full max-w-[630px] break-words">
                    {this.props.student_details &&
                    this.props.student_details?.first_one_on_one_session_notes.length > 0
                      ? this.props.student_details.first_one_on_one_session_notes.map((item) => (
                          <>
                            {item.session_recap === '' && item.teacher_notes === '' ? (
                              'No notes entered by teacher'
                            ) : (
                              <>
                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                  <TextWithLinks text={item.teacher_notes} />
                                </div>
                                <br />
                                <TextWithLinks text={item.session_recap} />
                              </>
                            )}
                          </>
                        ))
                      : 'No 1-on-1 Session taken in trial'}
                  </div>
                </p>
              </div>
            </div>

            {this.props.student_details.show_yoga_journey === 1 ? (
              <div className="vs-basic-container">
                <button className="btn btn-pri" onClick={this.open_student_yoga_journey}>
                  Yoga Journey
                </button>
                <div
                  className="dashboard-session-chat"
                  onClick={() => {
                    this.open_chat_screen();
                  }}
                >
                  <div className="d-s-c-c">
                    <img
                      src="https://images.myyogateacher.com/teacher_web_app/ic_chat.png"
                      alt="chat"
                    />
                    <div className="d-s-c-b">Chat</div>
                  </div>
                  {this.state.channelUnread > 0 ? (
                    <div className="custom-chat-badge">{this.state.channelUnread}</div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {this.state.isScheduleClass && (
          <DashboardScheduleClassPopup
            editPopupData={scheduleData}
            closePopup={this.closeScheduleClassPopup}
            closeSchedulePopup={this.closeSchedulePopup}
            openCreditPopup={(dt) => this.openCreditPopup(dt)}
            closesubscriptionPopupFun={this.closesubscriptionPopupFun}
          />
        )}
        {this.state.sessionScheduledSuccess && (
          <ScheduleConfirmation closeSessionSuccess={this.closeSessionSuccess} />
        )}
        {this.state.subscritiopnPopup && (
          <SubscritionPopup
            closesubscriptionPopupFail={this.closesubscriptionPopupFail}
            closesubscriptionPopup={this.closesubscriptionPopup}
            subscritiopnPopupDataDT={this.state.subscritiopnPopupDataDT}
            closePopup={this.closeSubscriptionPopupdt}
            subscriptionPopupData="a093d28d-76c5-4b6a-9b26-bda1ba53515c"
          />
        )}
        <div className="main-c-footer">
          <div
            className={`${getScreenSize ? 'main-c-footer-cont' : 'w-full flex justify-between px-3'}`}
          >
            <button className="btn btn-back" onClick={this.goToHome}>
              <img src="https://images.myyogateacher.com/back_arrow_btm.png" alt="back arrow" />
              Back
            </button>
            <div>
              <button className="btn btn-sec" onClick={this.openBookCoaching}>
                Book with another coach
              </button>
              <button className="btn btn-pri " onClick={this.openScheduleClassPopup}>
                Book with me
              </button>
            </div>
          </div>
        </div>
        {this.state.open_sch_sess_with_student ? (
          <SelDateTime
            student_timezone={this.props.student_details.iana_timezone}
            student_uuid={this.props.student_details.uuid}
            close_sch_sess_w_stu={this.close_sch_sess_w_stu}
            sch_confirm={this.sch_confirm}
          />
        ) : null}
        {this.state.confirm_sch_session ? <Confirm /> : null}
      </>
    );
  };

  render_loading = () => (
    <div className="vs-loading">
      <Spinner />
    </div>
  );

  impersonateStudent = () => {
    const { uuid } = this.props.student_details;
    this.props.impersonate_myt_user_teacher_consultancy({
      user_uuid: uuid,
      user_type: 'STUDENT'
    });
  };

  render() {
    return (
      <div className="main-card-body">
        {this.state.loading ? this.render_loading() : this.render_student_details()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  student_details: selectors.get_student_details(state),
  student_loading_status: selectors.student_loading_status(state),
  teacher_details: state.profile.teacher_details,
  is_admin_imp: state.home.is_admin_imp,
  impersonate_myt_user_teacher_consultancy_response:
    state.home.impersonate_myt_user_teacher_consultancy_response,
  impersonate_myt_user_teacher_consultancy_response_status:
    state.home.impersonate_myt_user_teacher_consultancy_response_status,
  consult_call_active: state.home.consult_call_active,
  scheduleSessionDataInfo: state.dashboard.scheduleSessionDataInfo,
  schedule_class_status: state.classdetails.schedule_class_status,
  schedule_class_response: state.classdetails.schedule_class_response
});

const mapDispatchToProps = (dispatch) => ({
  load_student_details: (payload) => {
    dispatch(actions.load_student_details(payload));
  },
  load_student_details_slug: (payload) => {
    dispatch(actions.load_student_details_slug(payload));
  },
  set_home_variable: (key, payload) => {
    dispatch(actions.set_home_variable(key, payload));
  },
  impersonate_myt_user_teacher_consultancy: (key, payload) => {
    dispatch(actions.impersonate_myt_user_teacher_consultancy(key, payload));
  }
});

const StudentProfileWithRedux = connect(mapStateToProps, mapDispatchToProps)(StudentProfile);
const StudentProfileWithRouter = withRouter(StudentProfileWithRedux);

export default StudentProfileWithRouter;
